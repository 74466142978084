<template>
  <p v-if="data_array.length === 0">{{ $t('misc.empty') }}</p>

  <DataTable resizable-columns ref="dtexport" :loading="loading" v-if="data_array.length !== 0" :value="data_array"><template #header>
    <div class="flex justify-content-between">
      <Button icon="pi pi-file-excel" label="Export" class="p-mr-2" @click="exportCSV()"/>
    </div>
  </template>
    <Column v-for="col of requests['columns']" :key="col.hash" :field="col.hash" :header="col.title" style="max-width: 300px;"></Column>

    <template #loading>
      {{ $t('misc.loading') }}
    </template>

  </DataTable>

</template>
<script>
import {generalhelper} from "@/helpers/generalhelper";

export default {
  name: 'RequestsReport',
  props: ['requests'],
  watch: {
    requests: function (newVal, oldVal) {
      this.data_array = []
      Object.keys(newVal['data']).forEach((key) => {
        this.data_array.push(newVal['data'][key])
      })
      this.loading = false
    }
  },
  data() {
    return {
      data_array: [],
      loading: true
    }
  },
  methods: {
    exportCSV() {
      this.$refs.dtexport.exportCSV();
    },
    //TODO Get questions title and reorganize columns
    exportExcel() {
      import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(this.$refs.dtexport.processedData);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array'
        });

        this.saveAsExcelFile(excelBuffer, 'products');
      });
    },
    saveAsExcelFile(buffer, fileName) {
      generalhelper.saveAsExcelFile(buffer, fileName);
    }
  }
}
</script>

<style scoped>
:deep(.p-datatable-wrapper) {
  width: 100%;
  overflow: scroll;
}

:deep(td) {
  max-width: 30vw;
  border: 1px solid #e2e8f0;
  overflow: scroll;
  word-break: break-word;
}

</style>
