import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL + '/eeerequests';

class RequestService {
  getRequests(params) {
    return axios.get(API_URL + '?' + params);
  }

  getRequest(id) {
    return axios.get(API_URL + '/' + id);
  }

  getParticipants(id) {
    return axios.get(API_URL + '/' + id + '/participants');
  }

  createRequest(data) {
    return axios.post(API_URL, data)
  }

  updateRequest(data) {
    return axios.put(API_URL + '/' + data._id, data)
  }

  deleteRequest(id) {
    return axios.delete(API_URL + '/' + id)
  }

  validateRequest(id) {
    return axios.patch(API_URL + '/' + id + '/validate')
  }

  changeRequestStatus(request_id, status_code, global_acceptance_rate = null, notify_teachers = false) {
    return axios.patch(API_URL + '/' + request_id + '/' + global_acceptance_rate + '/' + notify_teachers, {status_code})
  }

  reopenRequest(request_id, new_closing_date) {
    return axios.patch(API_URL + '/' + request_id + '/reopen/' + new_closing_date)
  }

  createMassEvaluation(data) {
    return axios.post(API_URL + '/massevaluation', data)
  }

  validateMassEvaluation(id) {
    return axios.patch(API_URL + '/massevaluation/' + id + '/validate')
  }

  deleteMassEvaluation(id) {
    return axios.delete(API_URL + '/massevaluation/' + id)
  }
}

export default new RequestService();
