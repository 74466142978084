import RequestService from '@/Services/request.service.js'
import router from "@/router";

const getDefaultState = () => {
  return {
    teacher: null,
    teaching: null,
    is_for_hec_lecture: false,
    courses: [],
    curriculum: [],
    semester: null,
    students: [],
    acadyear: null,
    faculty: null,
    request: null,
    originalsurvey: null,
    sendingdate: null,
    firstreminderdate: null,
    secondreminderdate: null,
    closingdate: null,
    isLoading: false,
    hasBeenCreated: false,
  }
}

export const request = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    store({commit, state}, component) {
      commit('setRequestIsLoading', true);
      let request = component.formObject
      request.steps = [
        {type: 'send', date: request.sending_date},
        {type: 'remind', date: request.first_reminder_date},
      ]
      if (request.second_reminder_date !== null) {
        request.steps.push({type: 'remind', date: request.second_reminder_date})
      }
      request.steps.push({type: 'close', date: request.closing_date})
      request.creator = JSON.parse(sessionStorage.getItem('userdata')).email

      //TODO use this instead of component's formObject
      RequestService.createRequest(request)
        .then(res => {
          //TODO understand why I can't use i18n here
          component.$toast.add({severity: 'success', summary: 'Requête créée.', life: 3000})
          commit('setRequestIsLoading', false);
          commit('setRequestHasBeenCreated', true);

          router.push('/')
          commit('resetState')
        })
        .catch(err => {
          component.$toast.add({severity: 'error', summary: err.response?.data.errors, life: 3000})
          commit('setRequestIsLoading', false);
        })
    },
    save({commit, state}, component) {
      commit('setRequestDetail', JSON.parse(JSON.stringify(component.formObject)))
    },
  },
  mutations: {
    setRequestDetail: (state, request) => {
      state.request = request
    },
    setRequestIsLoading: (state, isLoading) => {
      state.isLoading = isLoading
    },
    setRequestHasBeenCreated: (state, hasBeenCreated) => {
      state.hasBeenCreated = hasBeenCreated
    },
    setAcadYear: (state, acadyear) => {
      state.acadyear = acadyear
    },
    setFaculty: (state, faculty) => {
      state.faculty = faculty
    },
    setTeacher(state, teacher) {
      state.teacher = teacher
    },
    setTeaching(state, teaching) {
      state.teaching = teaching
    },
    setStudents(state, students) {
      state.students = students
    },
    setSemester(state, semester) {
      state.semester = semester
    },
    setCourses(state, courses) {
      let coursesArray = []
      courses.forEach(course => {
        coursesArray.push({
          enstyid: course.enstyid,
          name: course.name,
          nbhours: course.nbhours,
          periodicity: course.periodicity
        })
      })
      state.courses = coursesArray
    },
    setCurriculum(state, courses) {
      let curriculum = []
      courses.forEach(course => {
        course.cursus.forEach(cursus => {
          curriculum.push(cursus.name)
        })
      })
      state.curriculum = [...new Set(curriculum)]

    },
    setOriginalSurvey(state, survey) {
      state.originalsurvey = survey
    },
    setSendingDate(state, sendingDate) {
      state.sendingdate = sendingDate
    },
    setFirstReminderDate(state, firstReminderDate) {
      state.firstreminderdate = firstReminderDate
    },
    setSecondReminderDate(state, secondReminderDate) {
      state.secondreminderdate = secondReminderDate
    },
    setClosingDate(state, closingDate) {
      state.closingdate = closingDate
    },
    resetState(state) {
      Object.assign(state, getDefaultState())
    },
    setIsForHECLecture(state, is_for_hec_lecture) {
      state.is_for_hec_lecture = is_for_hec_lecture
    }
  },
}

