import {generalhelper} from "@/helpers/generalhelper";

export class requesthelper {

  static ON_HOLD = 0
  static APPROVED = 1
  static INVITATIONS_SENT = 2
  static PROCESSING_RESULTS = 3
  static ANONYMISED = 4
  static RESULTS_AVAILABLE = 5

  /**
   * Extract speakers list as string in order to be able to use the filters
   * @param request
   */
  static extract_speakers_as_list(request) {
    request.speakers_list = request.speakers.map(speaker => speaker.email).join(', ')
  }

  /**
   * Add closing date to the request
   * @param request
   * @param locale
   */
  static add_closing_date(request, locale) {
    request.steps.forEach(step => {
      if (step.type === 'close') {
        request.closing_date = generalhelper.formatDate(step.date, locale)
        request.closing_date_timestamp = step.date
      }
    })
  }

  static hide_anonymised_status_from_teachers(request) {
    if(request.status === requesthelper.ANONYMISED && sessionStorage.getItem('currentrole') === 'user') {
      request.status = requesthelper.PROCESSING_RESULTS
    }
  }
}
