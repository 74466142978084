import {createI18n} from "vue-i18n";
import fr from "@/translations/fr.json";
import en from "@/translations/en.json";

const i18n = createI18n({
  locale: localStorage.getItem('lang') ?? window.navigator.language.split('-')[0],
  fallbackLocale: 'fr',
  messages: {
    fr: fr,
    en: en
  },
})

export default i18n
