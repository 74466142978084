<template>
  <div>
    <Card>
      <template v-slot:title>{{ $t("form.step4.title") }}</template>
      <template v-slot:content>

        <Fieldset :legend="$t('form.step1.description')">
          <div class="p-grid p-field">
            <label class="p-col-3">{{ $t("form.step1.fields.teachingname.label") }} : </label>
            <div class="p-col-9">
              <strong>{{ formData.teaching_name }} - {{
                  formData.semester === 'P' ? $t('form.step1.fields.semester.first') : $t('form.step1.fields.semester.second')
                }} {{ formData?.semester === 'P' ? formData?.academic_year + 1 : formData?.academic_year }}</strong>
            </div>
          </div>

          <div class="p-grid p-field">
            <label class="p-col-3">{{ $t("form.step1.fields.faculty.label") }} : </label>
            <div class="p-col-9">
              <p><b>{{ formData.faculty }}</b></p>
            </div>
          </div>

          <div class="-p-grid">
            <div class="p-grid p-field">
              <label class="p-col-3">{{ $t("form.step1.fields.courses.label") }} : </label>
              <div class="p-col-9">
                <Listbox :options="formData.courses">
                  <template #option="slotProps">
                    <div>
                      <span>{{ slotProps.option.name }} ({{ slotProps.option.periodicity }})</span>
                    </div>
                  </template>
                  <template #empty>
                    <div>{{ $t("misc.no_courses") }}</div>
                  </template>
                </Listbox>
              </div>
            </div>


            <div class="p-grid p-field">
              <label class="p-col-3">{{ $t("form.step1.fields.speakers.label") }} : </label>
              <div class="p-col-9">
                <Listbox :options="formData.speakers">
                  <template #option="slotProps">
                    <div>
                    <span>{{ slotProps.option.name }} ({{ slotProps.option.email }}) - {{
                        slotProps.option.role
                      }}</span>
                    </div>
                  </template>
                </Listbox>
              </div>
            </div>
          </div>

          <div class="p-field p-grid">
            <label class="p-col-3">{{ $t('form.step2.fields.student_list.label') }}</label>
            <div class="p-col-9">
              <Panel :toggleable="true" :collapsed="true">
                <Chips v-model="formData.students" separator="," data-label="name"/>
              </Panel>
            </div>
          </div>

        </Fieldset>

        <Divider/>

        <Fieldset :legend="$t('form.step3.title')">

          <div class="p-grid">

            <div class="p-col-4">
              <label>{{ $t('form.step3.fields.surveytype.existing.surveychoice.label') }} :</label>
              <p class="p-mb-5"><b>{{ JSON.parse(formData.original_survey).title }}, </b> {{ $t('form.step3.fields.surveytype.' + formData.survey_type + '.label') }}</p>
              <p class="p-mb-5" v-if="formData.survey_modifications"><b>{{ formData.survey_modifications }}</b></p>
            </div>

            <div class="p-col-8">
              <ul>
                <li class="p-mt-4">{{ this.$t('form.step3.fields.sendingdate.label') }} :
                  <b>{{ formData.sending_date?.toLocaleString(this.$i18n.locale) }}</b></li>
                <li class="p-mt-4">{{ this.$t('form.step3.fields.firstreminder.label') }} :
                  <b>{{ formData.first_reminder_date?.toLocaleString(this.$i18n.locale) }}</b></li>
                <li class="p-mt-4">{{ this.$t('form.step3.fields.secondreminder.label') }} :
                  <b>{{
                      formData.second_reminder_date?.toLocaleString(this.$i18n.locale) || this.$t('form.step3.fields.secondreminder.none')
                    }}</b>
                </li>
                <li class="p-mt-4">{{ this.$t('form.step3.fields.closingdate.label') }} :
                  <b>{{ formData.closing_date?.toLocaleString(this.$i18n.locale) }}</b></li>
              </ul>

              <Divider type="dashed"/>

              <label>{{ $t('form.step3.fields.resulttime.label') }} :</label>
              <p><b>{{ formData.receipt_time_limit }} {{ $t('misc.days') }}</b></p>

              <label>{{ $t('form.step3.fields.synthesis_required.label') }} :</label>
              <p><b>{{ formData.synthesis_required ? $t('misc.yes') : $t('misc.no') }}</b></p>
            </div>

          </div>
        </Fieldset>

      </template>

      <template v-slot:footer>
        <div class="p-grid p-nogutter p-justify-between">
          <Button :label='$t("misc.back")' @click="prevPage()" icon="pi pi-angle-left"/>
          <Button :label='$t("form.buttons.sendrequest")' @click.once="complete()" icon="pi pi-check"
                  iconPos="right"
                  class="p-button-success"/>
        </div>
      </template>

    </Card>
  </div>
</template>

<script>
export default {
  name: 'Summary',
  props: {
    formData: Object
  },
  data() {
    return {
      events: null
    }
  },
  mounted() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  },
  methods: {
    prevPage() {
      this.$emit('prev-page', {pageIndex: 3});
    },
    complete() {
      this.$emit('complete');
    },
  }
}
</script>
<style>
.p-divider-solid.p-divider-horizontal:before {
  border: 0
}
</style>
